@import "@styles/includes.scss";
.link-wrapper {
  display: flex;
  gap: rem(8);
  align-items: center;
}

.link-large {
  @extend %body-b2-semi-bold;
  font-weight: var(--medium);
}
.link-medium {
  @extend %body-b3-semi-bold;
}
.link-small {
  @extend %label-l1-semi-bold;
  font-weight: var(--medium);
}
