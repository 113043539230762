%body-b1 {
  line-height: 1.5;
  font-size: rem(18);
}

%body-b1-regular {
  @extend %body-b1;
  font-weight: var(--regular);
}
%body-b1-medium {
  @extend %body-b1;
  font-weight: var(--medium);
}
%body-b1-semi-bold {
  @extend %body-b1;
  font-weight: var(--semi-bold);
}

%body-b2 {
  font-size: rem(16);
  line-height: 1.5;
}

%body-b2-regular {
  @extend %body-b2;
  font-weight: var(--regular);
}
%body-b2-medium {
  @extend %body-b2;
  font-weight: var(--medium);
}
%body-b2-semi-bold {
  @extend %body-b2;
  font-weight: var(--semi-bold);
}

%body-b2-b3 {
  font-size: rem(14);
  line-height: 1.6;
  @include media('>tablet-lg') {
    font-size: rem(16);
    line-height: 1.5;
  }
}

%body-b3 {
  font-size: rem(14);
  line-height: 1.6;
}

%body-b3-regular {
  @extend %body-b3;
  font-weight: var(--regular);
}
%body-b3-medium {
  @extend %body-b3;
  font-weight: var(--medium);
}

%body-b3-semi-bold {
  @extend %body-b3;
  font-weight: var(--semi-bold);
}
