@import "@styles/includes.scss";
.paper {
  box-shadow: var(--shadow-neutral-5);

  &:not(.is-fullscreen) {
    border-radius: 8px;
    margin: 10px;
  }
}

.backdrop {
  background: rgba(20, 29, 24, 0.5);
}

.heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;

  @include media('<phone-lg') {
    padding: rem(15) rem(24) 0;
  }

  @include media('>=phone-lg', '<tablet-lg') {
    padding: rem(24) rem(24) 0;
  }

  @include media('>=tablet-lg') {
    padding: rem(20) rem(24) 0;
  }

  &.is-centered {
    justify-content: center;
  }

  &.is-reversed {
    flex-direction: row-reverse;

    h2 {
      margin-left: rem(4);
    }
  }

  h2 {
    flex: 1;
    color: var(--color-black);
    font-weight: var(--medium);
    line-height: 1.2;
    font-size: rem(18);
  }
}

.close {
  position: relative;

  &.is-reversed {
    margin-left: rem(-4);
  }

  &:not(.is-reversed) {
    margin-left: rem(16);
    margin-right: rem(-4);
  }

  &.is-primary {
    color: var(--color-primary);
  }

  &.is-dark {
    color: var(--color-text-primary);
  }

  &.is-black {
    color: var(--color-black);
  }
}
