@import "@styles/includes.scss";
// .picker {
//   // Select div if don't have attr class
//   // & > div > div > div:first-child:not([class]) {
//   //   display: none;
//   // }
// }

.picker-sunday {
  button:not([class*='Mui-selected']) {
    color: var(--color-primary);
  }
}

.footer {
  @extend %center;
  width: 100%;
  border-top: 1px solid var(--color-neutral-200);
}

.summary {
  @extend %center;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;

  @include media('<tablet-lg') {
    padding: rem(24) 0 rem(8);
  }

  @include media('>=tablet-lg') {
    padding: rem(12) 0;
  }

  p {
    color: var(--color-text-primary);
    font-size: rem(12);
    line-height: 1.4;
    text-transform: capitalize;

    span {
      color: var(--color-neutral-400);
    }
  }
}
