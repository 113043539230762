@import "@styles/includes.scss";
.root {
  display: flex;
  align-items: center;
  border-radius: rem(8);
  padding: rem(8) rem(15);

  p {
    @extend %label-l1-regular;
  }

  &.is-error {
    background-color: var(--color-error-100);
    border: 1px solid var(--color-error);

    i {
      color: var(--color-error);
    }

    p {
      color: var(--color-error);
    }
  }

  &.is-info {
    background-color: var(--color-info-100);
    border: 1px solid var(--color-informative);
    color: var(--color-informative);

    i {
      color: var(--color-informative);
    }
  }
  &.is-warning {
    background-color: #fef7ea;
    border: 1px solid var(--color-warning);

    i {
      color: var(--color-warning);
    }

    p,
    span {
      color: var(--color-orange-secondary-dark);
    }
  }
}
