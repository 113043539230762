%label-l1 {
  font-size: rem(12);
  line-height: 1.5;
}

%label-l1-regular {
  @extend %label-l1;
  font-weight: var(--regular);
}
%label-l1-medium {
  @extend %label-l1;
  font-weight: var(--medium);
}
%label-l1-semi-bold {
  @extend %label-l1;
  font-weight: var(--semi-bold);
}

%label-l2 {
  font-size: rem(10);
  line-height: 1.6;
  font-weight: var(--regular);
}
%label-l2-regular {
  @extend %label-l2;
  font-weight: var(--regular);
}

%label-l2-semi-bold {
  @extend %label-l2;
  font-weight: var(--semi-bold);
}
