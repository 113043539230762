@import "@styles/includes.scss";
.root {
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
  }
}

.content {
  @extend %center;
  flex-direction: column;

  p {
    font-size: 1.6rem;
    line-height: 1.2;
    color: var(--color-text-secondary);
  }
}

/* SVG spinner icon animation */
.spinner {
  animation: rotate 2s linear infinite;

  .path {
    stroke: var(--color-primary);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
