@import "@styles/includes.scss";
.paper {
  padding: rem(16) rem(24);
  border-radius: rem(10);
  box-shadow: var(--shadow-neutral-5);
}

.date-range-picker {
  // Select div if don't have attr class
  & > div > div > div:first-child:not([class]) {
    display: none;
  }
}

.date-range-picker-day {
  button:not(.Mui-selected) {
    color: var(--color-primary);
  }
}

.date-range-footer {
  @extend %center;
  width: 100%;
  border-top: 1px solid var(--color-neutral-200);
}

.date-range-summary {
  @extend %center;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: rem(12) 0;

  p {
    color: var(--color-text-primary);
    font-size: rem(12);
    line-height: 1.4;

    span {
      color: var(--color-neutral-400);
    }
  }
}
