@import "@styles/includes.scss";
.chevron {
  transition: transform 0.3s ease-in-out;
}

.chevron.rotate {
  transform: rotate(180deg);
}

.btn-descarga {
  display: 'flex';
  justify-content: 'flex-start';
  padding: 0;
  color: var('--color-text-title-p1');
}
.card-collapse {
  @media (min-width: 648px) {
    max-width: calc(50% - 18px);
  }
}

.card-servicios {
  @media (max-width: 527px) {
    max-width: calc(50% - 8px);
  }
  @media (min-width: 528px) {
    max-width: calc(33% - 18px);
  }
  @media (min-width: 832px) {
    max-width: 16rem;
  }
}
